/* Small Sidebar Container */
.small-sidebar-container {
  background-color: var(--primary-color);
  padding-left: 4px;
  padding-top: 5px;
}

/* Small Sidebar */
.small-sidebar {
  /* max-width: 300px; */
  height: 99vh;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 6px; */
  border-radius: 25px 0 0 35px;
}

/* Logo */
.small-sidebar .logo-tag {
  margin-top: 0.75rem;
}

/* Menu Styles */
.custom-small-sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0.4rem 0rem 0.4rem;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border-top: 1px solid var(--stroke-color);
}

/* Menu Items */
.custom-menu-item {
  margin: 6px 0;
  /* height: 30px;
  width: 30px; */
  border-radius: 8px;
  /* font-size: 24px; */
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  color: var(--active-text-color);
  font-weight: var(--active-text-fw) !important;
  transition: all 0.3s ease;
  cursor: pointer;
}

/* Menu Item Hover */
.custom-menu-item:hover {
  background-color: var(--small-menu-icon-bg) !important;
  color: white !important;
  font-weight: bold;
}
.custom-menu-item:active {
  scale: 0.9;
}
.custom-menu-item.selected {
  background-color: var(--small-menu-icon-bg) !important;
  color: white !important;
  font-weight: bold;
}

/* Large Sidebar */
.large-sidebar {
  background-color: var(--primary-color) !important;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid var(--stroke-color);
}
.large-sidebar::-webkit-scrollbar {
  visibility: hidden;
  width: 2px;
}
.large-sidebar.scroll-bar::-webkit-scrollbar {
  width: 2px;
}
.large-sidebar::-webkit-scrollbar-thumb {
  visibility: hidden;
}
.large-sidebar.scroll-bar::-webkit-scrollbar-thumb {
  visibility: visible;
}

.large-sidebar.hidden {
  width: 0 !important;
  min-width: 0 !important;
  max-width: 0 !important;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.large-sidebar.visible {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  transition: all 0.3s ease-in-out;
}

.large-sidebar-header {
  border-bottom: 1px solid var(--stroke-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 4px;
}

.hamburger-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--active-text-color) !important;
  margin-right: 10px;
}

.hamburger-btn:hover {
  color: var(--normal-text-color) !important;
}

.hamburger-btn:active {
  scale: 0.9;
}
/* Sidebar Tabs */
.sidebar-tabs-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--accent-color);
  margin-top: 10px;
}

.custom-tabs {
  display: flex;
  justify-content: center;
  padding: 2px 2px;
  gap: 4px;
}

.tab-item {
  font-size: 12px;
  font-weight: 500;
  padding: 6px 8px;
  cursor: pointer;
  color: var(--normal-text-color);
  background-color: transparent;
  transition: all 0.2s ease-out;
}

.tab-item:active {
  scale: 0.9;
}

.tab-item.active {
  color: var(--active-text-color);
  font-weight: bold;
  border-top: 2px solid var(--active-text-color);
  border-radius: 11px;
  /* letter-spacing: 0.1rem; */
}

.tab-content .ant-menu-item-group-title {
  display: none;
}

.quick-access-menu {
  background-color: var(--primary-color) !important;
  height: 22vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.quick-access-menu::-webkit-scrollbar {
  width: 6px;
}

.quick-access-menu::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 4px;
}

.quick-access-menu::-webkit-scrollbar-track {
  background-color: var(--primary-color);
}

.quick-access-menu .ant-menu-item {
  color: var(--active-text-color) !important;
  font-weight: 500;
  background-color: transparent !important;
}
.quick-access-menu .ant-menu-item:hover {
  color: var(--active-text-color) !important;
  background-color: transparent !important;
}

.quick-access-menu .ant-menu-item.ant-menu-item-selected {
  color: var(--active-text-color) !important;
  background-color: transparent !important;
}

.quick-access-menu .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.quick-access-menu .content .unpin {
  opacity: 0;
  transition: all 0.3s ease-in;
}

.quick-access-menu .content .unpin:active {
  transform: scale(0.8);
}

.quick-access-menu .content:hover .unpin {
  opacity: 1;
}
/* Sidebar Tabs-> Quick Access Edit */
.drag-lock-container {
  position: relative;
  padding-bottom: 5px;
}

.drag-lock-container .drag-lock {
  position: absolute;
  top: 0;
  left: 90%;
  transform: translate(10%, -30%);
  color: var(--active-text-color);
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3 ease-in;
  cursor: pointer;
}
.drag-lock-container .drag-lock:active {
  scale: 0.9;
}

.dashboard-menu {
  background-color: var(--primary-color) !important;
  height: 22vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard-menu .ant-menu-item {
  color: var(--normal-text-color);
}

.dashboard-menu .ant-menu-item:hover {
  color: var(--active-text-color) !important;
  background-color: var(--primary-color) !important;
}
.dashboard-menu .ant-menu-item.ant-menu-item-selected {
  color: var(--active-text-color) !important;
  background-color: transparent !important;
}

/* Animations for Sidebar Transition */
.large-sidebar,
.small-sidebar {
  transition: all 0.3s ease-in-out;
}

/* Active Sidebar Container */
.acitve-sidebar-container {
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 6px;
  margin: 3px 0;
  height: auto;
}

/* Active Item Header */
.active-item-header {
  padding: 6px 12px;
  margin-bottom: 9px;
  font-size: 13px;
  border-radius: 6px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  background-color: var(--active-menu-bg) !important;
}

.active-item-header p {
  display: flex;
  color: var(--active-text-color) !important;
}

.subMenuTitle {
  color: var(--normal-text-color) !important;
  padding: 8px;
  letter-spacing: 1px;
  font-size: 13px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.subMenuTitle i {
  cursor: pointer;
  transition: all 0.1s ease-in;
}
.subMenuTitle i:active {
  scale: 0.8;
}
/* Active Submenu Container */
.active-item-menus {
  display: flex;
  align-items: flex-start;
  position: relative;
  border-radius: 5px;
  padding: 1px 0;
  /* border: 1px solid red; */
}

/* Submenu Marker (Vertical Line) */
.active-submenu-marker {
  width: 1px;
  background-color: #d9d9d9;
  margin-right: 12px;
  border-radius: 0px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
}

/* Submenu Items */
.active-item-submenus {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;
  padding: 3px 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 25px;
}

.active-item-submenus p {
  color: var(--active-text-color) !important;
  margin-right: 10px;
  word-wrap: break-word;
}
.pin {
  opacity: 0;
  transition: all 0.3s ease-in;
  font-size: 14px;
  font-weight: 800;
  color: var(--normal-text-color);
}
.pin:active {
  scale: 0.7;
}

.active-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--active-text-color);
  left: 2.5%;
  top: 40%;
}

.active-item-menus:hover {
  background-color: var(--accent-color) !important;
}

.active-item-menus.active {
  background-color: var(--active-menu-bg);
}

.active:hover .pin {
  opacity: 100;
  color: var(--active-text-color);
}

@media (max-width: 768px) {
  .large-sidebar {
    height: 85vh;
    border-radius: 0px 0px 8px 0px;
    box-shadow: var(--input-shadow) !important;
  }
  .large-sidebar.visible {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent overlay */
    z-index: 4; /* Below sidebar */
  }
}

/* SideHeader  */
.side-header-container {
  background-color: var(--primary-color);
  border-bottom: 1px solid var(--accent-color);
  height: auto;
  padding: 8px 16px;
}
.side-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-utilities-left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.search-utilities-right {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.header-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.livecall-icon-container {
  position: relative;
  width: fit-content;
  /* border: 1px solid red; */
  padding: 2px 4px;
}

.livecall-icon-container .call-count {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(10%, -30%);
  color: var(--active-text-color) !important;
  padding: 4px 8px;
  font-size: 0.8rem;
  font-weight: bold;
}

.top-icons {
  color: var(--text-color) !important;
}

.header-search {
  display: flex;
  align-items: center;
  flex-grow: 0;
  margin-left: auto;
  background-color: var(--search-bg);
  padding: 0px 4px;
  border-radius: 4px;
  width: 150px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.header-search:hover {
  border: 1px solid var(--active-text-color);
}

.header-search .search-input {
  border: none;
  outline: none;
  background: transparent;
  margin-left: 8px;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: var(--active-text-color);
}

.header-search .search-input::placeholder {
  color: var(--active-text-color);
}

.header-search .search-icon {
  color: var(--active-text-color);
}
.route-search-container {
  position: relative;
}
.search-results {
  position: absolute;
  background: var(--accent-color);
  border: 1px solid var(--stroke-color);
  width: 150px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.search-result-item {
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  color: var(--active-text-color);
  background: var(--search-bg);
}

.search-result-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.no-result {
  text-align: center;
  color: gray;
}
.header-right {
  display: flex;
  align-items: center;
  gap: 9px;
}

.header-price {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 13px;
  color: var(--active-text-color);
  font-weight: 700;
  margin-left: 12px;
}

.header-logo-btn {
  background-color: #ff4d4f !important;
  color: white !important;
  font-weight: bold;
  width: 22px;
  height: 22px;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.header-logo-btn:hover {
  scale: 1.1;
}
/* Avatar Dropdown */

.avatar-dropdown-container {
  background-color: var(--primary-color) !important;
  border-radius: 4px;
  transition: all 0.3s ease-in;
}
.avatar-dropdown-container.ant-menu-vertical > .ant-menu-item {
  height: 35px !important;
  line-height: 35px !important;
}

.avatar-menu-item {
  color: var(--active-text-color) !important;
}

.avatar-menu-item:hover {
  background-color: var(--secondary-color) !important;
}
.header-theme-container {
  padding: 5px 0px 0px 5px;
  margin-left: 10px;
  color: var(--active-text-color);
  cursor: pointer;
}

.theme-switcher {
  margin-left: 8px;
  background-color: var(--accent-color) !important;
}

@media screen and (max-width: 768px) {
  .header-search {
    display: flex;

    align-items: center;
    flex-grow: 0;
    margin-left: auto;
    background-color: var(--search-bg);
    padding: 2px;
    border-radius: 8px;
    width: 100px;
    margin-bottom: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .header-search .search-input {
    border: none;
    outline: none;
    background: transparent;
    margin-left: 8px;
    width: 100%;
    font-size: 12px;
    color: var(--active-text-color);
  }

  .header-search .search-input::placeholder {
    color: var(--active-text-color);
  }

  .header-search .search-icon {
    color: var(--active-text-color);
  }
  .header-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--accent-color);
    padding-top: 8px;
    gap: 2rem;
  }
}
.header-tenant-text {
  margin-left: 20px;
}

.header-tenant-text a {
  color: var(--active-text-color);
  font-size: 12px;
  font-weight: 500;
}
.header-tenant-text a:hover {
  color: var(--normal-text-color);
}
.header-tenant-menu {
  background-color: var(--primary-color) !important;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(235, 233, 233, 0.6);
  border: 0.5px solid var(--stroke-color);
}
.ant-menu {
  color: var(--active-text-color) !important;
}
.anticon.ant-input-clear-icon {
  color: var(--popup-ok);
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: var(--primary-color) !important;
  color: var(--active-text-color) !important;
}

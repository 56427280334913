.popup-form {
  padding: 0;
}
.popup-form .popup-heading p {
  font-size: 18px;
  font-weight: 500;
  color: var(--active-text-color);
}

.popup-form .ant-modal-content {
  background-color: var(--popup-body-bgColor);
  border-radius: 8px;
}
.popup-form .ant-modal-content .ant-modal-close-x {
  color: var(--active-text-color);
}
.popup-form .ant-modal-content .ant-modal-header {
  background-color: var(--popup-header-bgColor);
  border-bottom: var(--popup-border);
  border-radius: 8px 8px 0px 0px;
}
.popup-form .ant-modal-content .ant-modal-header .ant-modal-title {
  color: var(--popup-header-fontColor);
}

.popup-form .ant-modal-content .ant-modal-footer {
  border-top: white;
}

.popup-form .ant-modal-footer .ant-btn {
  background-color: var(--popup-cancel);
  color: var(--active-text-color);
  border: none;
  border-radius: 4px;
}

.popup-form .ant-modal-footer .ant-btn-primary {
  background-color: #1890ff;
  color: white;
  /* border: 1px solid var(--popup-border); */
  border-radius: 4px;
}

.ant-picker-separator {
  color: var(--active-text-color) !important;
}

/* Popup form */

.popup-form .ant-modal-body .ant-form .ant-form-item-label > label {
  color: var(--active-text-color);
}
.popup-form .ant-modal-body .ant-form .ant-input {
  background-color: transparent;
  border-radius: 4px;
  color: var(--active-text-color) !important;
  border-color: var(--active-text-color) !important;
}

.popup-form .ant-modal-body .ant-form .ant-input:hover {
  border-color: var(--active-text-color);
}
.popup-form .ant-modal-body .ant-form .ant-input-password {
  background-color: transparent;
  border-radius: 4px;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  background-color: transparent !important;
  border-color: red;
}
.ant-input-password-icon {
  color: var(--active-text-color) !important;
}
.popup-form .ant-modal-body .ant-form .ant-input-password:hover {
  border-color: var(--active-text-color);
}

.popup-form .ant-input-affix-wrapper .ant-input-password {
  color: var(--active-text-color);
}
.react-tel-input .form-control {
  color: var(--active-text-color) !important;
  background: transparent !important;
  border-radius: 0px !important;
  border: 1px solid var(--active-text-color) !important;
  width: 100% !important;
  height: 28px !important;
  border-radius: 9px;
}
.react-tel-input .form-control:hover {
  border: 1px solid var(--active-text-color) !important;
}
.react-tel-input .flag-dropdown {
  background: var(--accent-color) !important;
  border: 1px solid var(--active-text-color) !important;
}
.react-tel-input .selected-flag:hover {
  background: var(--popup-border) !important;
  border: none !important;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  color: var(--active-text-color) !important;
}
.popup-form
  .ant-modal-body
  .ant-form
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--active-text-color) !important;
  background: var(--popup-ok) !important;
  border-color: var(--popup-border) !important;
}
.popup-form .ant-modal-body .ant-form .ant-radio-button-wrapper {
  background: var(--popup-cancel);
  color: var(--active-text-color);
}
.popup-form .ant-modal-body .ant-form .ant-radio-button-wrapper:hover {
  background: var(--popup-cancel);
  color: var(--active-text-color);
}
.popup-form .ant-modal-body .ant-form .ant-radio-button-wrapper {
  background: var(--popup-cancel);
  color: var(--active-text-color);
}
.popup-form .ant-modal-body .ant-form .ant-radio-button-wrapper:hover {
  background: var(--popup-cancel);
  color: var(--active-text-color);
}
.radio-group {
  display: flex;
  gap: 1rem;
}

.radio-button {
  position: relative;
  display: inline-block;
  background-color: var(--popup-body-bgColor);
  color: var(--popup-header-fontColor);
  border: 1px solid var(--popup-border);
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.radio-button.active {
  background-color: var(--popup-ok);
}

.radio-button input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.radio-button:hover {
  border-color: var(--popup-ok);
}

.radio-button.active:hover {
  background-color: var(--popup-ok);
}
.popup-form .pesudo-labels {
  color: var(--active-text-color);
}
.popup-form .ant-select {
  color: var(--active-text-color);
}
.popup-form
  .ant-modal-body
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: transparent;
  border: 1px solid var(--active-text-color) !important;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-arrow {
  color: var(--active-text-color) !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: var(--active-text-color) !important;
  /* box-shadow: 0 0 0 2px rgba(128, 128, 128, 0.2) !important; */
  box-shadow: var(--input-shadow) !important;
  border-right-width: 1px !important;
  outline: 0;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--active-text-color) !important;
  box-shadow: var(--input-shadow) !important;
  border-right-width: 1px !important;
  outline: 0;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--active-text-color) !important;
  box-shadow: var(--input-shadow) !important;
  border-right-width: 1px !important;
  outline: 0;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: var(--active-text-color);
  background: transparent;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: transparent !important;
}

/* Background color for the entire dropdown */
.ant-select-dropdown {
  background: var(--accent-color) !important; /* Set desired background */
  border-radius: 4px !important;
}

/* Text color for all options */
.ant-select-item-option {
  color: var(--active-text-color) !important; /* Set desired text color */
}

/* Background and text color for selected option */
.ant-select-item-option-selected {
  background-color: var(--accent-color) !important;
  color: var(--active-text-color) !important;
}

/* Background and text color for hovered option */
.ant-select-item-option-active {
  background-color: var(--accent-color, #f0f0f0) !important;
  color: var(--active-text-color, #000) !important;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-left: 1rem; /* equivalent to mx-4 */
  margin-top: 0.5rem; /* equivalent to mt-2 */
}

.custom-checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid var(--accent-color);
  border-radius: 2px;
  background-color: transparent;
  margin-right: 0.5rem;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.custom-checkbox:checked {
  border: 1px solid var(--accent-color);
  position: relative;
  color: var(--active-text-color);
}

.custom-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid var(--active-text-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-label {
  font-size: 14px;
  color: var(--active-text-color);
  cursor: pointer;
}

.popup-form .ant-switch-checked {
  background-color: var(--popup-ok) !important;
}

.popup-form .ant-switch {
  background-color: var(--popup-cancel);
}

.settings-form .ant-input {
  background: transparent !important;
  color: var(--active-text-color);
  border-radius: 4px;
}

.settings-form .ant-input:hover {
  border: 1px solid var(--active-text-color) !important;
}
.settings-form .ant-input:disabled {
  color: var(--active-text-color) !important;
}
.settings-form .ant-input-affix-wrapper {
  background: transparent !important;
}
@media (max-width: 768px) {
  .settings-form div {
    flex-direction: column;
  }

  .settings-form .ant-form-item {
    width: 100%;
  }

  .settings-form .ant-select {
    width: 100% !important;
  }
}

/* Ensure spacing between form items for better readability */
.settings-form .ant-form-item {
  margin-bottom: 12px;
}

/* Ensure submit button is properly aligned */
.settings-form .custom-button {
  width: 100%;
  max-width: 200px;
  display: block;
  margin: 0 auto;
}

.lookup-select.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: transparent !important;
  border: 0.05rem solid #aeadad !important;
  /* border-left: none !important; */
  border-radius: 0px 4px 4px 0px !important;
  transition: all 0.3scubic-bezier(0.645, 0.045, 0.355, 1);
}
.lookup-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100% !important;
  height: 30px !important;
  padding: 0 7px !important;
}
/* Modify the calendar icon inside Ant Design Input */
/* .ant-input[type="date"]::-webkit-calendar-picker-indicator {
  color: red !important;
  opacity: 0.8;
  cursor: pointer;
} */

.tab-shad .ant-tabs-nav .ant-tabs-tab-active {
  background-color: var(--active-tab-bg) !important;
  border: none !important;
  border-radius: 4px !important;
}
.tab-shad .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--active-text-color);
}
/* Customizing the switch container */
.custom-switch.ant-switch {
  background-color: var(--popup-ok) !important; /* Green when ON */
}

/* Customizing the switch when checked (ON state) */
.custom-switch.ant-switch-checked {
  background-color: #1890ff !important;
}

/* Customizing the switch handle (toggle button) */
.custom-switch .ant-switch-handle {
  background-color: #afafaf !important;
  border-radius: 50%;
}

/* Adding shadow effect on hover */
.custom-switch:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
}

/* Adjusting transition for smooth effect */
.custom-switch.ant-switch {
  transition: all 0.3s ease-in-out !important;
}

/* Styling the entire Radio Group */
.custom-radio.ant-radio-group {
  display: flex !important;
}

/* Customizing the individual Radio Buttons */
.custom-radio .ant-radio-button-wrapper {
  background-color: transparent !important;
  color: var(--active-text-color) !important;
  border: 1px solid #d9d9d9 !important;
  /* padding: 4px 8px !important; */
}

/* Hover Effect */
.custom-radio .ant-radio-button-wrapper:hover {
  background-color: var(--popup-ok) !important;
  color: var(--active-text-color) !important;
}

/* Styling when a radio button is selected */
.custom-radio .ant-radio-button-wrapper-checked {
  background-color: var(--popup-ok) !important;
  color: var(--active-text-color) !important;
  /* border-color: var(--accent-color) !important; */
}
.custom-radio .ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px !important;
}
.custom-radio .ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0 !important;
}
.filterborder {
  border: 1px solid var(--accent-color);
  border-radius: 8px;
}
.custom-input.ant-input {
  background: transparent;
  color: var(--active-text-color);
  border: 1px solid var(--active-text-color);
  border-radius: 4px;
}
.custom-input.ant-input:hover {
  border: 1px solid var(--active-text-color) !important;
}
.custom-input.ant-input-disabled {
  background: transparent !important;
  color: var(--normal-text-color) !important;
  border: 1px solid var(--accent-color) !important;
  border-radius: 4px;
}
.custom-input.ant-input-disabled:hover {
  border: 1px solid var(--accent-color) !important;
}
.custom-input.ant-input-affix-wrapper.ant-input-password {
  background: transparent !important;
  color: var(--active-text-color) !important;
  border: 1px solid var(--active-text-color) !important;
  border-radius: 4px !important;
}

/* Ensure hover effect works */
.custom-input.ant-input-affix-wrapper.ant-input-password:hover {
  border-color: var(--active-text-color) !important;
}

/* Ensure the input inside also has the correct text color */
.custom-input.ant-input-affix-wrapper.ant-input-password .ant-input {
  color: var(--active-text-color) !important;
  background: transparent !important;
}
.ant-page-header-back-button {
  color: var(--active-text-color) !important;
}

/* Style the main input-number wrapper */
.custom-input-number.ant-input-number {
  background: transparent !important;
  color: var(--active-text-color) !important;
  border: 1px solid var(--active-text-color) !important;
  border-radius: 4px !important;
  width: 100% !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: var(--invert);
  opacity: 0.7;
  cursor: pointer;
  transition: filter 0.3s ease-in-out;
}

/* Hover effect */
.custom-input-number.ant-input-number:hover {
  border-color: var(--active-text-color) !important;
}

/* Style the input inside */
.custom-input-number.ant-input-number .ant-input-number-input {
  color: var(--active-text-color) !important;
  background: transparent !important;
  border: none !important;
}

/* Style the increment & decrement buttons */
.custom-input-number .ant-input-number-handler {
  background: var(--accent-color) !important;
  color: var(--active-text-color) !important;
  border: none !important;
}

/* Hover effect for handlers */
.custom-input-number .ant-input-number-handler:hover {
  background: var(--accent-color, gray) !important;
}

/* Style the icons inside the handlers (up/down arrows) */
.custom-input-number .ant-input-number-handler .anticon {
  color: var(--active-text-color) !important;
}

/* Ensure focus styles are applied */
.custom-input-number.ant-input-number-focused {
  border-color: var(--accent-color) !important;
  box-shadow: 0 0 5px var(--accent-color) !important;
}

.ant-select-multiple .ant-select-selection-item {
  background: transparent !important;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: var(--active-text-color) !important;
}

.ant-popover-inner {
  background-color: var(--accent-color) !important;
}
.ant-popover-message {
  color: var(--active-text-color) !important;
}
/* Target the Cancel button */
.ant-popover-buttons {
  display: flex !important;
  justify-content: space-between !important;
}
.ant-popover-buttons .ant-btn {
  background-color: var(--popup-ok) !important;
  color: var(--active-text-color) !important;
  border: none !important;
  margin: 2px !important;
  border-radius: 4px;
}

/* Target the OK button specifically */
.ant-popover-buttons .ant-btn-primary {
  background-color: var(--popup-cancel) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--active-text-color) !important;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid var(--normal-text-color) !important;
}
/* .anticon {
  color: var(--active-text-color) !important;
} */
.ant-select-tree {
  background: transparent !important;
  color: var(--active-text-color) !important;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: var(--popup-cancel) !important;
}
.ant-select-tree
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: var(--popup-cancel) !important;
}
.react-tel-input .country-list {
  background-color: var(--accent-color) !important;
}
.react-tel-input .country-list .country-name {
  color: var(--active-text-color) !important;
}
.react-tel-input .country-list .country .dial-code {
  color: var(--normal-text-color) !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #afafaf !important;
}
.react-tel-input .country-list .country:hover {
  background-color: var(--popup-cancel) !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: var(--popup-ok) !important;
  border-radius: 3px 0 0 0;
}

/* Calendar Styles */
.ant-picker-panel-container {
  background: var(--accent-color) !important;
}
.ant-picker-header button {
  color: var(--active-text-color) !important;
}
.ant-picker-content th {
  color: var(--active-text-color) !important;
}
.ant-picker-dropdown {
  color: var(--normal-text-color) !important;
}
.ant-picker-cell {
  color: var(--normal-text-color) !important;
}
.ant-picker-cell-in-view {
  color: var(--active-text-color) !important;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: var(--popup-ok) !important;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: var(--active-text-color) !important;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: var(--popup-ok) !important;
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  background: var(--popup-cancel) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: var(--active-text-color) !important;
  background: var(--popup-ok) !important;
  border-color: #a0a0a0 !important;
}
.ant-picker-header {
  color: var(--active-text-color) !important;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: var(--active-text-color) !important;
  background: var(--filter-fill-bg) !important;
  border-color: #a0a0a0 !important;
}

.ant-picker-ok .ant-btn-primary {
  background-color: var(--popup-ok) !important;
  color: var(--active-text-color) !important;
  border-color: #a0a0a0 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #a0a0a0 !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: var(--active-breadcrumb-color) !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: var(--active-text-color) !important;
}

/* TABLE STYLES */

.pesudo-alpha {
  padding: 2px 2px !important;
  margin: 0px 4px;
  border-radius: 8px;
  background-color: var(--primary-color) !important;
}
.table-utils-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 4px;
}
.table-utils-container .table-utils-heading {
  color: var(--active-text-color) !important;
  font-size: 18px;
  font-weight: 400;
  padding: 0px 3px;
}

.table-utils-container .table-utils-actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
}

.pesudo-beta {
  background-color: var(--primary-color) !important;
  border-radius: 8px;
}

.pesudo-beta .custom-search {
  max-width: 300px !important;
  background-color: var(--primary-color) !important;
}
.custom-search .ant-input {
  outline: none !important;
  border: 1px solid var(--stroke-color);
  border-radius: 4px 0px 0px 4px !important;
  background-color: var(--primary-color) !important;
  color: var(--active-text-color) !important;
}
.custom-search .ant-input-affix-wrapper {
  outline: none !important;
  border: 1px solid var(--stroke-color);
  border-radius: 4px 0px 0px 4px !important;
  background-color: var(--primary-color) !important;
}
.custom-search .ant-input-affix-wrapper .ant-input {
  background-color: var(--primary-color) !important;
  color: var(--active-text-color) !important;
}
.custom-search .ant-input-affix-wrapper:hover {
  border: 0.5px solid var(--stroke-color) !important; /* Explicitly set the border */
  box-shadow: 0 0 0 2px var(--stroke-color) !important; /* Ensure custom focus styles apply */
  outline: none !important; /* Remove the browser's default outline */
}

.custom-search .ant-input-group-addon {
  background-color: var(--primary-color);
}
.custom-search .ant-btn-primary {
  color: var(--normal-text-color) !important;
  background: var(--accent-color);
  border: none;
}
.custom-search .ant-btn-primary:hover {
  background: var(--accent-color);
  color: var(--active-text-color) !important;
}

.custom-search .ant-btn {
  border-radius: 0px 4px 4px 0px !important;
}

.custom-button .ant-btn-primary {
  width: fit-content !important;
  padding: 0 12px;
  background: var(--accent-color);

  border-radius: 4px;
  color: var(--active-text-color) !important;
}

button.ant-btn.ant-btn-primary.custom-button {
  width: fit-content !important;
  padding: 0 16px;
  background: var(--active-button-fill);
  border: 1px solid var(--custom-btn-border);
  border-radius: 4px;
  color: var(--active-text-color) !important;
  transition: all 0.3s ease-in-out;
}
button.ant-btn.ant-btn-primary.custom-button:active {
  scale: 0.9;
}
button.ant-btn.ant-btn-primary.custom-button:disabled {
  background: var(--accent-color) !important;
  color: var(--active-text-color) !important;
}

/* General table design */
.design {
  border-radius: 8px !important;
  padding: 4px;
}

.design .ant-table:hover {
  background-color: inherit !important;
}
.design .ant-table {
  background-color: inherit;
}
.design .ant-table-content {
  border: none !important;
}

/* Table header styles */
.design .ant-table-thead > tr > th {
  background-color: var(--table-header) !important;
  color: var(--active-text-color) !important;
  font-weight: 600 !important;
  text-align: left !important;
  border-left: 0.5px solid var(--stroke-color) !important;
  border-bottom: none !important ;
}

/* Table body row styles */
.design .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: var(--table-odd-row-bg) !important;
  color: var(--active-text-color) !important;
  /* color: var(--active-text-color) !important; */
  text-align: left !important;
  border-left: 0.5px solid var(--stroke-color) !important;
  border-bottom: none !important;
}

.design .ant-table-tbody > tr:nth-child(even) > td {
  background-color: var(--table-even-row-bg) !important;
  /* color: var(--active-text-color) !important; */
  color: var(--active-text-color) !important;
  text-align: left !important;
  border-left: 0.5px solid var(--stroke-color) !important;
  border-bottom: none !important;
}
.design .ant-table-tbody > tr:last-child > td {
  border-bottom: 0.5px solid var(--stroke-color) !important;
}
/* Hover effect on table rows */
.design .ant-table-tbody > tr:hover td {
  background-color: unset !important;
}

/* Remove borders across the table */
.design .ant-table-container {
  border: none !important;
  border-radius: 6px;
}

.design .ant-table-content > table,
.design .ant-table-header > table {
  border: none !important;
}

.design .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed::after {
  border: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: var(--table-hover-bg) !important;
}

/* Action buttons styling */
.design .ant-btn {
  border-radius: 4px !important;
}

.copy-btn {
  background-color: var(--regular-action-color) !important;
  color: var(--active-text-color) !important;
  border: 1px solid var(--regular-action-border) !important;
}

.delete-btn {
  background-color: var(--danger-action-color) !important;
  color: var(--active-text-color) !important;
  border: 1px solid var(--danger-action-border) !important;
}
.edit-btn {
  background-color: var(--edit-action-color) !important;
  color: var(--active-text-color) !important;
  border: 1px solid var(--edit-action-border) !important;
}
.key-btn {
  background-color: var(--key-action-color) !important;
  color: var(--active-text-color) !important;
  border: 1px solid var(--key-action-border) !important;
}
.view-btn {
  background-color: var(--view-action-color) !important;
  color: var(--active-text-color) !important;
  border: 1px solid var(--view-action-border) !important;
}
.design .close-btn {
  background-color: #585858 !important;
  color: #ffffff !important;
  border: 1px solid #404040 !important;
}

.design .pause-btn {
  background-color: var(--pause-action-color) !important;
  color: var(--active-text-color) !important;
  border: 1px solid var(--pause-action-border) !important;
  padding: 3px;
}
.ant-tooltip-disabled-compatible-wrapper {
  border: none !important;
  background: none !important;
}
.ant-tooltip-disabled-compatible-wrapper .anticon {
  color: black !important;
}
.design .play-btn {
  background-color: var(--play-action-color) !important;
  color: var(--active-text-color) !important;
  border: 1px solid var(--play-action-border) !important;
}

/* Pagination styles */
.design .ant-pagination-item {
  background-color: var(--primary-color) !important;
  color: var(--active-text-color) !important;
}

.design .ant-pagination {
  color: var(--active-text-color) !important;
}
.design .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
  color: var(--active-text-color);
}
.design .ant-pagination-item-active {
  border-radius: 4px;
  border: 1px solid var(--custom-btn-border) !important;
  background-color: var(--active-button-fill) !important;
}
.design .ant-pagination-item-active a {
  color: var(--active-text-color);
}
.design .ant-pagination-item-active a:hover {
  color: var(--active-text-color);
}
.design .ant-pagination-item a {
  color: var(--active-text-color) !important;
}
.design .ant-select {
  color: var(--active-text-color);
  border-radius: 4px;
  background-color: var(--regular-action-color);
  border: var(--regular-action-border);
}
.design .ant-select-arrow {
  color: var(--active-text-color);
}

.design .ant-empty-normal {
  color: #9e9e9e !important;
}

.action-button {
  width: 100% !important;
  border: none !important;
  background-color: #3f3f40d4 !important ;
  color: white !important;
  border-radius: 8px !important;
  margin-top: 10px !important;
}
.search-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid var(--custom-btn-border);
  overflow: hidden;
}

.search-container:hover {
  border: 1px solid gray; /* Subtle border */
}

.search-input {
  flex: 1;
  padding: 2px 8px;
  font-size: 12px;
  color: var(--active-text-color);
  background: transparent;
  border: none;
  outline: none;
}

.search-button {
  background: var(--active-button-fill);
  padding: 6px 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-button svg {
  color: var(--active-text-color);
}

.search-button:hover {
  background: transparent;
}

/* Cross (Clear) Button */
.clear-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-button svg {
  color: gray;
  transition: color 0.2s ease-in-out;
}

.clear-button:hover svg {
  color: #aeadad;
}

.ant-empty-normal {
  color: var(--active-text-color) !important;
}
.expanded-table {
  width: 100%;
  /* max-width: 330px; */
  border-collapse: collapse;
  background: transparent;
  border-radius: 8px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--accent-color);
}

.expanded-table td {
  padding: 5px;
  border-bottom: 1px solid var(--accent-color);
}

.expanded-table .expanded-title {
  font-weight: 600;
  color: var(--active-text-color);
  text-align: left;
  width: 10%;
  border-right: 0.5px solid var(--stroke-color);
}

.expanded-table .expanded-value {
  font-weight: 400;
  color: var(--normal-text-color);
  text-align: left;
  width: 60%;
  padding-left: 20px;
  color: var(--active-text-color);
}

/* Alternate row colors */
.expanded-table .even-row {
  background-color: var(--table-even-row-bg);
}

.expanded-table .odd-row {
  background-color: var(--table-odd-row-bg);
}

/* Remove bottom border for the last row */
.expanded-table tr:last-child td {
  border-bottom: none;
}
.ant-table-row-expand-icon {
  margin-left: 1rem;
  padding: 0 !important;
  background: transparent !important;
  border: 1px solid var(--active-text-color) !important;
}
.ant-table-row-expand-icon:active {
  color: var(--active-text-color) !important;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: var(--active-text-color) !important;
}
.ant-table-summary {
  position: relative;
  z-index: 2;
  background: var(--table-summary-fill) !important;
}
.ant-table-summary .ant-table-cell {
  color: var(--active-text-color) !important;
}
.ant-table-summary .ant-table-cell strong {
  font-weight: 500 !important;
}
.ant-table-column-sorter {
  margin-left: 4px;
  color: gray !important;
  font-size: 0;
  transition: color 0.3s;
}
.ant-picker-seperator {
  color: var(--active-text-color) !important;
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: none !important;
  border-top: none !important;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}
.ant-collapse {
  border: none !important;
}

/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100% !important;
  height: 30px !important;
  padding: 0 7px !important;
} */
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: var(--active-text-color) !important;
}
.ant-list-item {
  color: var(--active-text-color) !important;
}
@media (max-width: 768px) {
  .pesudo-beta .custom-search {
    width: auto !important;
    background-color: var(--primary-color) !important;
  }
  .table-utils-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .table-utils-container .table-utils-heading {
    color: var(--active-text-color) !important;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .table-utils-container .table-utils-actions {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .search-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 350px;
    background: transparent;
    border-radius: 6px;
    border: 0.01rem solid var(--custom-btn-border);
    overflow: hidden;
  }
  .expanded-table {
    width: 100%;
    max-width: 330px;
    border-collapse: collapse;
    background: transparent;
    border-radius: 8px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--accent-color);
  }
  .expanded-table .expanded-title {
    font-weight: 600;
    color: var(--active-text-color);
    text-align: left;
    width: 40%;
    border-right: 1px solid rgb(148, 148, 148);
  }

  .expanded-table .expanded-value {
    font-weight: 400;
    color: var(--normal-text-color);
    text-align: left;
    width: 60%;

    color: var(--active-text-color);
  }
}

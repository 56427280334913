/* Edit popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: var(--primary-color);
  color: var(--active-text-color);
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.popup-content h2 {
  padding-top: 10px;
  color: var(--active-text-color);
}
.popup-header {
  position: relative;
}

.popup-close {
  position: absolute;
  top: 10;
  right: 0;
  background-color: transparent;
  border: none;
  font-size: px;
  color: var(--normal-text-color);
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.popup-close:hover {
  color: var(--active-text-color);
}
.dNd-content {
  background-color: var(--primary-color) !important;
  height: auto;
  color: var(--normal-text-color);
}

.dNd-content .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dNd-content .ant-menu-item {
  background-color: transparent !important;
  color: var(--normal-text-color) !important;
  margin-top: 8px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.dNd-content.drag-animation .ant-menu-item {
  transform: scale(1);
  box-shadow: 0 4px 10px var(--accent-color);
  cursor: grab;
  margin-top: 2px;
  border-radius: 8px;
  border: 1px solid var(--accent-color);
}

.dNd-content .ant-menu-item:hover,
.dNd-content .ant-menu-item.ant-menu-item-selected {
  color: var(--active-text-color) !important;
  background-color: transparent !important;
}

.login-container {
  width: 400px;
  background: var(--login-fill);
  color: var(--active-text-color);
  padding: 10px 40px;
  height: 500px;
  border-radius: 8px;
}
.login-logo-header h1 {
  font-size: 32px;
  color: var(--active-text-color);
  font-weight: bold;
  margin-top: 20px;
}
.login-form-header {
  margin: 4px 8px;
}
.login-form-header h2 {
  color: var(--active-text-color);
}
.login-form-header p {
  color: var(--normal-text-color);
}

.login-form-input.ant-input {
  background: transparent;
  padding: 8px 16px;
  border-radius: 8px;
  color: var(--active-text-color);
}
.login-form-input.ant-input:hover {
  background: transparent;
  padding: 8px 16px;
  border-radius: 8px;
  color: var(--active-text-color);
  border: 1px solid var(--active-text-color) !important;
}
.login-form-input-password {
  background: transparent !important;
  border-radius: 8px;
}

/* Fix for the internal input field */
.login-form-input-password .ant-input {
  background: transparent !important;
  padding: 8px 16px !important;
  border-radius: 8px;
  color: var(--active-text-color);
}

/* Optional: Fix the wrapper itself */
.login-form-input-password.ant-input-affix-wrapper {
  border-radius: 8px !important;
  background: transparent !important;
}
.login-form-input-password.ant-input-affix-wrapper:hover {
  border-radius: 8px !important;
  background: transparent !important;
  border: 1px solid var(--active-text-color) !important;
}
/* Optional: Ensure proper focus styling */
.login-form-input-password.ant-input-affix-wrapper:focus-within {
  border-color: var(--normal-text-color) !important;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
}
.login-form-input-password .ant-input-suffix .ant-input-password-icon {
  color: var(--normal-text-color) !important;
}

.login-form-input-password .ant-input-suffix:hover {
  color: var(--active-text-color) !important;
}

.login-form-label .ant-form-item-label > label {
  color: var(--active-text-color) !important;
}

.login-form-button {
  margin-top: 10px;
}
.login-form-button .ant-btn-primary {
  width: 100% !important;
  padding: 0px 12px;
  background: #1890ff;
  border: none;
  border-radius: 4px;
  color: white !important;
}

button.ant-btn.ant-btn-primary.login-form-button {
  width: 100% !important;
  height: 4rem !important;
  padding: 0px 16px;
  background: #1890ff;
  border: none;
  border-radius: 8px;
  color: white !important;
  transition: all 0.3s ease-in-out;
}
button.ant-btn.ant-btn-primary.login-form-button:active {
  scale: 0.9;
}

.logs {
  background: var(--secondary-color) !important;
}
.logs-header {
  display: flex;
  justify-content: flex-start;
  padding-left: 5px;
  padding-bottom: 5px;
}

.logs-header p {
  font-size: 18px;
  font-weight: 400;
  color: var(--active-text-color);
}
.logs-main-header p {
  font-size: 16px;
  font-weight: 400;
  color: var(--active-text-color);
}
.ant-page-header-heading-title {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--active-text-color) !important;
  margin-left: 5px !important;
}
/* Cascader container */
.logs-cascader.ant-select {
  border-radius: 8px;
  background-color: transparent;
  width: 100%;
  border: 1px solid var(--stroke-color);
  transition: all 0.3s ease-in-out;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent !important;
  border: 0.05rem solid #aeadad !important; /* Apply border */
  /* border-left: none !important;  */
  border-radius: 4px !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-dropdown {
  color: var(--active-text-color) !important;
}
.ant-cascader-menu-item:hover {
  background: var(--accent-color) !important;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: var(--popup-ok) !important;
}

/* Selected item inside the selector */
.logs-cascader .ant-select-selection-item {
  color: var(--active-text-color, #333);
  font-size: 14px;
}

/* Arrow dropdown icon */
.logs-cascader .ant-select-arrow {
  color: var(--primary-color, #1890ff);
  font-size: 12px;
}

/* Dropdown menu */
.logs-cascader .ant-cascader-menus {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color);
  overflow: hidden;
}

/* Menu items in the dropdown */
.logs-cascader .ant-cascader-menu-item {
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.logs-cascader .ant-cascader-menu-item:hover {
  background-color: var(--primary-clor, #f5f5f5);
}

/* Hover and focus states for the Cascader */
.logs-cascader.ant-select:hover,
.logs-cascader.ant-select-focused {
  border-color: var(--primary-color, #1890ff);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* RangePicker container */
.logs-randge-picker.ant-picker {
  width: 100%;
  border-radius: 4px;
  background-color: transparent;
  border: 0.05rem solid #aeadad !important;
  transition: all 0.3s ease-in-out;
}

/* Input inside RangePicker */
.logs-randge-picker .ant-picker-input {
  border-radius: 8px;
  background-color: transparent;
  padding: 0px 8px;
  height: auto;
}

/* RangePicker placeholder and text */
.logs-randge-picker .ant-picker-input input {
  color: var(--active-text-color, #333);
  background-color: transparent;
  border: none;
  outline: none;
}
.logs-randge-picker .ant-picker-range-seperator {
  color: var(--active-text-color, gray) !important;
}

/* Focused or active state */
.logs-randge-picker.ant-picker-focused {
  border-color: var(--stroke-color, #1890ff);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* RangePicker dropdown menu */
.logs-randge-picker .ant-picker-panel-container {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--accent-color) !important;
  overflow: hidden;
}

/* Dropdown menu items (ranges) */
.logs-randge-picker .ant-picker-presets {
  padding: 8px !important;
}

.logs-randge-picker .ant-picker-presets li {
  border-radius: 4px;
  padding: 4px 8px; /* Spacing for each preset */
  transition: background-color 0.3s ease-in-out; /* Smooth hover effect */
}

.logs-randge-picker .ant-picker-presets li:hover {
  background-color: var(
    --hover-color,
    #f5f5f5
  ); /* Highlighted background on hover */
}

/* Hover state */
.logs-randge-picker.ant-picker:hover {
  border-color: var(--stroke-color, #1890ff); /* Border on hover */
}

/* Disabled state */
.logs-randge-picker.ant-picker-disabled {
  background-color: #f5f5f5; /* Disabled background color */
  border-color: #d9d9d9; /* Disabled border color */
  cursor: not-allowed; /* Show not-allowed cursor */
}

/* Time picker inside RangePicker */
.logs-randge-picker .ant-picker-time-panel {
  border-radius: 8px; /* Rounded corners for time picker dropdown */
}

/* Optional: Add custom arrow styles */
.logs-randge-picker .ant-picker-suffix {
  color: var(
    --active-text-color,
    #1890ff
  ) !important; /* Customize arrow color */
}

.filter-actions-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  flex-wrap: wrap;
  padding-left: 2px;
}
.filter-actions-container .filter-action {
  background-color: var(--filter-fill-bg);
  border: 1px solid #a0a0a0;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  color: var(--active-text-color);
}
.filter-actions-container .filter-action:hover {
  background-color: var(--filter-fill-bg);
  color: var(--active-text-color);
  border: 1px solid #a0a0a0 !important;
}
.timeline-header {
  padding: 5px !important;
  border-radius: 8px;
  background-color: var(--logs-background-color) !important;
}

.timeline-header
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: var(--filter-fill-bg) !important;
  border: 1px solid #a0a0a0 !important;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: var(--active-text-color) !important;
}
.timeline-header .ant-select-arrow {
  color: var(--active-text-color) !important;
}

.logs-container {
  padding: 5px !important;
  border-radius: 8px;
  background-color: var(--logs-background-color) !important;
}

.ant-page-header-heading-title {
  color: var(--active-text-color) !important;
}

.ant-btn.export-csv {
  background-color: var(--active-button-fill);
  border: 1px solid #a0a0a0;
  border-radius: 3px;
  color: var(--active-text-color);
}

.log-tabs .ant-tabs-nav-list .ant-tabs-tab {
  color: var(--active-text-color) !important;
}
.log-tabs .ant-tabs-nav-list .ant-tabs-tab-btn:active {
  color: var(--active-text-color) !important;
}
.log-tabs
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--active-text-color) !important;
  border-top: 2px solid var(--active-text-color);
  border-radius: 5px;
  padding: 0px 2px;
}
.ant-tabs-ink-bar {
  background: unset !important;
}

.log-tabs .ant-badge {
  color: var(--active-text-color) !important;
}
.ant-btn.export-csv:hover {
  background-color: var(--filter-fill-bg);
  color: var(--active-text-color);
  border-color: var(--regular-action-border);
}

.tenant-user-tabs .ant-tabs-tab .tenant-tab-item {
  border-bottom: 2px solid var(--accent-color);
  border-radius: 8px;
  margin: 4px;
  padding: 4px;
}
.tenant-user-tabs .ant-tabs-tab-active {
  background-color: transparent !important;
}
.tenant-user-tabs .ant-tabs-tab:hover {
  background-color: transparent !important;
}
.tenant-user-tabs .ant-tabs-tab-active .tenant-tab-item:not(.status-tabs) {
  background-color: var(--active-tab-bg);
  border-right: 2px solid var(--active-text-color) !important;
  border-top: none !important;
}

.status-badge {
  color: var(--active-text-color) !important;
}
.ant-tabs-tab-active .status-badge {
  border-top: 2px solid var(--active-text-color);
  border-radius: 4px;
  padding-top: 4px !important;
}

.ant-form-item-label > label {
  color: var(--active-text-color) !important;
}
.ant-select {
  color: var(--active-text-color) !important;
}

.filter-action-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  gap: 10px;
  /* border-top: 2px solid var(--accent-color); */
  border-radius: 4px;
  margin-bottom: 10px;
}

button.ant-btn.ant-btn-primary.clear-filter {
  width: fit-content !important;
  padding: 0 16px;
  background: var(--popup-cancel);
  border: 1px solid var(--custom-btn-border);
  border-radius: 4px;
  color: var(--active-text-color) !important;
  transition: all 0.3sease-in-out;
}

button.ant-btn.ant-btn-primary.apply-filter {
  width: fit-content !important;
  padding: 0 16px;
  background: #1890ff;
  /* border: 1px solid var(--custom-btn-border); */
  border-radius: 4px;
  color: white !important;
  transition: all 0.3sease-in-out;
}

.settings-columns .ant-menu {
  background: var(--accent-color);
  color: var(--active-text-color);
  border-radius: 4px;
  height: 100%;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 5px 0px !important;
}

.settings-columns .ant-menu::-webkit-scrollbar {
  width: 0.2rem;
}

.settings-columns .ant-menu::-webkit-scrollbar-thumb {
  background: var(--active-text-color); /* Color of the scrollbar */
  border-radius: 4px;
}

.settings-columns .ant-menu::-webkit-scrollbar-track {
  background: transparent;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #a0a0a0 !important;
  border-color: var(--active-text-color) !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black !important;
  border-color: var(--active-text-color) !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: var(--accent-color) !important;
  color: white !important;
}
.ant-menu-light .ant-menu-item:hover {
  color: var(--active-text-color) !important;
}
.ant-drawer-wrapper-body {
  background: var(--primary-color);
}
.ant-tree {
  color: var(--active-text-color) !important;
  background: transparent !important;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: var(--normal-text-color) !important;
}
.ant-tabs-dropdown-menu {
  background-color: var(--accent-color) !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  margin-left: 11px;
}

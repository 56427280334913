.mobile-navigation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--secondary-color);
  border-radius: 0px 0px 20px 20px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  z-index: 20;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-top: 2px solid var(--accent-color);
}

/* Left Icon (Initially Logo, Becomes Button) */
.mobile-left-icon {
  font-size: 14px;
  cursor: pointer;
  color: var(--active-text-color);
  transition: color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-right: 2px solid var(--accent-color);
}

/* Scrollable Menu Wrapper */
.custom-mobile-menu-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  max-width: 300px;
  position: relative;
  scroll-behavior: smooth;
}
.custom-mobile-menu-wrapper::-webkit-scrollbar {
  display: none;
}

/* Scrollable Menu */
.custom-mobile-menu {
  display: flex;
  gap: 15px;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  padding: 3px 0;
  border-radius: 15px;
  position: relative;
  width: max-content;
}

/* Menu Items */
.custom-mobile-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 45px;
  padding: 2px 4px;
}

.custom-mobile-menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  color: var(--active-text-color);
}

.custom-mobile-menu-item p {
  color: var(--active-text-color);
  transition: all 0.3s ease-in-out;
  font-size: 9px;
}

/* Selected Menu Item */
.custom-mobile-menu-item.selected {
  position: relative;
  z-index: 20;
}

.custom-mobile-menu-item.selected .active-icon-seperator {
  width: 40px;
  height: 20px;
  border-radius: 0 0 20px 20px;
  background-color: white;
  position: absolute;
  transform: translateY(-20%);
}

.custom-mobile-menu-item.selected .custom-mobile-menu-icon {
  background: var(--small-menu-icon-bg);
  color: var(--small-menu-icon-color);
  border-radius: 50%;
  transform: translateY(-15%);
  z-index: 10;
  position: relative;
  width: 35px;
  height: 35px;
  font-weight: bold;
}

.custom-mobile-menu-item.selected p {
  transform: translateY(-5%);
  z-index: 10;
  position: relative;
  font-weight: 600;
}

/* Right Scroll Button */
.mobile-icon-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-left: 2px solid var(--accent-color);
  background: transparent;
  font-size: 18px;
  color: var(--active-text-color);
  cursor: pointer;
  padding: 2px 6px;
  transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: -4px 0px 6px -2px rgba(0, 0, 0, 0.1);
}

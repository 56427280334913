@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Inter;
}
/* Default Layout  */

.default-layout-container {
  background-color: var(--secondary-color) !important;
  height: auto;
  overflow: hidden;
}
.default-layout-content {
  padding: 5px 5px;
  width: auto !important;
  height: 70vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.default-layout-content::-webkit-scrollbar {
  visibility: none;
}
/* BreadCrumbs */
.breadcrumb-container a {
  color: var(--inactive-breadcrumb-color) !important;
}

.ant-breadcrumb-separator {
  color: var(--inactive-breadcrumb-color) !important;
}
.breadcrumb-container .ant-breadcrumb-link {
  color: var(--active-breadcrumb-color) !important;
}

.web-breadcrumb {
  background-color: var(--secondary-color) !important;
}

/* COLORS */
:root {
  /* Default Theme (Light) */
  --primary-color: white;
  --secondary-color: #ebedf1;
  --accent-color: #f3f4f6;
  --stroke-color: #d9d9d9;
  --search-bg: #f3f4f6;
  --normal-text-color: #79797a;
  --normal-text-fw: 500;
  --active-text-color: #1c1e21;
  --active-text-fw: 600;
  --icon-bg: #1c1e21;
  --active-menu-bg: #f3f4f6;
  /* Table Colors */
  --table-header: #bcc2cb;
  --table-odd-row-bg: #ffffff;
  --table-even-row-bg: #eceff0;
  --link-color: #8b8bf8;
  /* Table Action Colors */
  --regular-action-color: #d1d2d2;
  --regular-action-border: #7c7d7d;
  --pause-action-color: #a6cfab;
  --pause-action-border: #3ea70b;
  --play-action-color: #e5c195;
  --play-action-border: #ea9306;
  --danger-action-color: #f0baba;
  --danger-action-border: #e51619;
  /* Bread-Crumb */
  --active-breadcrumb-color: black;
  --inactive-breadcrumb-color: #79797a;
  --logs-background-color: white;
  /* Popup forms */
  --popup-header-bgColor: #eff1f4;
  --popup-header-fontColor: black;
  --popup-body-bgColor: white;
  --popup-border: #7c7d7d;
  --popup-cancel: #d1d2d2;
  --popup-ok: #d1d2d2;
  --popup-toggle-fill: #d2d5da;
  --popup-toggle-btn: white;
  /* Active tab */
  --active-tab-bg: #bcc2cb73;
  --filter-border: #cccdd4;
  --switch-bg: #d2d5da;
  /* Login Page */
  --login-fill: #ffffff;
  --login-button: #7d7d7d;
  --divider-stroke: #d9d9d9;
  /* input shadow */
  --input-shadow: 0 0 0 2px rgba(128, 128, 128, 0.2);
  /* icons */
  --edit-action-color: #f4f0c3;
  --edit-action-border: #e8d20c;
  --key-action-color: #d5c4e2;
  --key-action-border: #a469d1;
  --view-action-color: #bfe9e9;
  --view-action-border: #0fb4b4;
  --small-menu-icon-bg: black;
  --small-menu-icon-color: white;
  /* table summary */
  --table-summary-fill: #d9d9d9;
  --custom-btn-border: #cccdd4;
  --invert: invert(0);
  --table-hover-bg: #d9d9d9;
  --filter-fill-bg: #f6f9f9;
  --active-button-fill: #f6f9f9;
}
[data-theme="dark"] {
  --primary-color: #323234;
  --secondary-color: #161618;
  --accent-color: #3f3f40;
  --stroke-color: #404040;
  --search-bg: #3f3f40;
  --normal-text-color: #dddddd;
  --normal-text-fw: 500;
  --active-text-color: #ffffff;
  --active-text-fw: 600;
  --icon-bg: #323234;
  --active-menu-bg: #3f3f40;
  /* Table Colors */
  --table-header: #060910;
  --table-odd-row-bg: #292929;
  --table-even-row-bg: #1e1e1e;
  /* Table Action Colors */
  --regular-action-color: #6e6d6d;
  --regular-action-border: #aeadad;
  --pause-action-color: #234f29;
  --pause-action-border: #13b528;
  --play-action-color: #563b1b;
  --play-action-border: #ee8a09;
  --danger-action-color: #552526;
  --danger-action-border: #e12226;
  /* Breadcrumbs */
  --active-breadcrumb-color: white;
  --inactive-breadcrumb-color: #979799;
  --logs-background-color: #272729;
  /* Popup forms */
  --popup-header-bgColor: #1e1e20de;
  --popup-header-fontColor: white;
  --popup-body-bgColor: #272729;
  --popup-border: #aeadad;
  --popup-cancel: #363636;
  --popup-ok: #6e6d6d;
  --popup-toggle-fill: #d2d5da;
  --popup-toggle-btn: white;
  /* Active tab */
  --active-tab-bg: #3f3f40d4;
  --filter-border: #404040;
  --switch-bg: #d2d5da;
  /* Login Page */
  --login-fill: #242424;
  --login-button: #3b3b3c;
  /* input shadow */
  --input-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
  /* icons */
  --edit-action-color: #646028;
  --edit-action-border: #eddc19;
  --key-action-color: #442953;
  --key-action-border: #ac52f1;
  --view-action-color: #2d6262;
  --view-action-border: #7cf4f4;
  --small-menu-icon-bg: #3f3f40;
  --small-menu-icon-color: white;
  /* Table Summary */
  --table-summary-fill: #3b3c3c;
  --custom-btn-border: #979799;
  --invert: invert(1);
  --table-hover-bg: #393939;
  --filter-fill-bg: #070707;
  --active-button-fill: #212121;

  --random: #7e08ac;
}
